var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("applyWorkTable", {
    ref: "applyWorkTable",
    attrs: { filterIndex: 3 },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }