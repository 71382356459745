<template>
    <applyWorkTable 
        ref="applyWorkTable" 
        :filterIndex="3" 
        >
    </applyWorkTable>
</template>

<script>
    import applyWorkTable from './layout/applyWorkTable.vue'
    import tableScrollTo from '#/js/mixins/tableScrollTo.js';

    export default {
        name: "FloatingListApplyList",
        components: {
            applyWorkTable
        },
        data(){
            return{
            }
        },
        mixins: [tableScrollTo],
        activated() {
            // this.$refs.applyWorkTable.tableTitleInit();
            this.tableScrollTo();
            this.$refs.applyWorkTable.resize();
            let _this = this;
            window.onresize = function () {
                _this.$refs.applyWorkTable.resize();
            }
        },
    }
</script>

<style scoped>
</style>
